
import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import { createTheme, ThemeProvider } from '@mui/material/styles';

import PhoneIcon from '@mui/icons-material/Phone';

const theme = createTheme();

export default function Navbar() {
    return <div id="navBar">
            <h1 id="logo">Hauraki Roof Painting</h1>

        <div id="number">
            <PhoneIcon />
            <a href="tel:0272326788" className="whiteLink"><p>027 232 6788</p></a>
        </div>
    </div>
}