
import Gallery from './Gallery';

import LocationOnIcon from '@mui/icons-material/LocationOn';

export default function Header() {
    return <div id="header">
        <Gallery />
        <div id="text">
            {/* <h1>
                CG Roof Painting
            </h1>

            <h5>
                Guaranteed quality workmanship and professional service.
            </h5> */}

            <p>
                Add value to your home with our roof painting services.
            </p>

            <div id="location">
                <LocationOnIcon />
                <p>Hauraki District & Surrounding Areas</p>
            </div>
        </div>
    </div>
}