import './App.css';

import { createTheme, ThemeProvider } from '@mui/material/styles';

import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Navbar from './components/navbar';
import Header from './components/Header';
import WorkShowcaseList from './components/ImageTiles';
import Footer from './components/Footer';
import ShowCase from './components/ShowCase';
import MessageUs from './components/MessageUs';
import GetQuote from './components/GetQuote';

const SMALL = 12

// First set of images in panel
const images1 = [
  // {
  //   location: "Invercargil",
  //   path: "https://i.imgur.com/QFvSZQl.jpeg",
  // },
  // {
  //   location: "Invercargil",
  //   path: "https://i.imgur.com/69AdmGj.jpeg",
  // },
  {
    location: "Invercargil",
    path: "https://i.imgur.com/FXVdVId.jpg",
  },
  {
    location: "Invercargil",
    path: "https://i.imgur.com/AgtYxqt.jpg",
  }
]

const images2 = [
  {
    location: "Invercargil",
    path: "https://i.imgur.com/SlnereN.jpg",
  },
  {
    location: "Invercargil",
    path: "https://i.imgur.com/mYXZfgT.jpg",
  },
  {
    location: "Invercargil",
    path: "https://i.imgur.com/4VdILdq.jpg",
  },
  {
    location: "Invercargil",
    path: "https://i.imgur.com/sKvzvjE.jpg",
  }
]

const images3 = [
  {
    location: "Invercargil",
    path: "https://i.imgur.com/lOJRaQh.jpg",
  },
  {
    location: "Invercargil",
    path: "https://i.imgur.com/3OjVaUc.jpg",
  },
  {
    location: "Invercargil",
    path: "https://i.imgur.com/CawV5OV.jpeg",
  },
  {
    location: "Invercargil",
    path: "https://i.imgur.com/E9LN3pa.jpeg",
  }
]


const theme = createTheme({
  typography: {
    fontFamily: '"Poppins", "Roboto", "Helvetica", "Arial"',
  },
  a: {
    decoration: 'none',
  }
})

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Navbar />

      <MessageUs />

      <Header />

      <Container sx={{ bgcolor: 'white', borderRadius: 2, p: 1 }}>
        <Grid container spacing={3}>
          <Grid item xs={SMALL} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
            <Box component="footer">
              <Typography variant="h5" align="center" color="black" paragraph sx={{ p: 4, pt: 8, pb: 0 }}>
                The benefits of painting your roof can prolong the shelf life of your roof by strengthening and
                protecting it from the harsh weather and the suns UV rays. Quality roof paint is affordable and
                lasts for long periods of time if applied correctly.
              </Typography>
            </Box>
          </Grid>

          <Grid item xs={SMALL} >
            {/* rgba(21,31,64,0.95) */}
            <Box sx={{ bgcolor: 'white', borderRadius: 2, p: 1, pt: 4 }} component="footer">
              <ShowCase first_image='https://i.imgur.com/sBDhHU5.png' second_image='https://i.imgur.com/r7r7Rra.png' />
            </Box>
          </Grid>

          <Grid item xs={SMALL} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
            <Typography sx={{ pt: 3, pb: 0 }} variant="h5" align="center" color="black" paragraph>
              The roof coating I use, Roofrite, is a environmentally friendly paint designed for New Zealand conditions using UV Resistant Pigments and tough Acrylic Polymers. Roofrite will provide a quality coating that will restore and add value to your property.<br />
            </Typography>
            <Typography sx={{ pt: 3, pb: 3 }} variant="h5" align="center" color="black" paragraph>
              15 Year warranty against flaking, blistering and peeling.
            </Typography>
          </Grid>

          <Grid item xs={SMALL}>
            <Box sx={{ bgcolor: 'white', borderRadius: 2, p: 1 }} component="footer">
              <ShowCase first_image='https://i.imgur.com/RKCPoe2.jpeg' second_image='https://i.imgur.com/MixJZ3g.jpeg' />
            </Box>
          </Grid>

          <Grid item xs={SMALL}>
            <Box sx={{ bgcolor: 'white', borderRadius: 2, p: 4, textAlign: 'center' }} component="footer">
              <Typography variant="h4" >CALL NOW FOR A FREE QUOTE!</Typography>
              <Typography variant="h3" sx={{pb: 2}}><a href="tel:0272326788" className="blackLink">027 232 6788</a></Typography>

              <Typography variant="h4"><GetQuote class="quoteContact" message="Or send me a message"/></Typography>
            </Box>
          </Grid>

          <Grid item xs={SMALL}>
            <Box sx={{ bgcolor: 'background.paper', p: 1, alignContent: 'center' }} component="footer">
              <WorkShowcaseList images={images3} />
            </Box>
          </Grid>

          <Grid item xs={SMALL} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
            <Typography variant="h4" align="center" sx={{ pt: 4, pb: 1 }}>
              Process:
            </Typography>
            <Typography sx={{ pt: 0, pb: 4 }} variant="h5" align="center" color="black" paragraph>
              Waterblast<br />
              Re-screw if needed<br />
              Rust treat if needed<br />
              Seal joins with paintable silicone<br />
              Apply roofing patches if needed<br />
              2-3 coats of Roofrite Roof paint
            </Typography>
          </Grid>

          <Grid item xs={SMALL}>
            <Box sx={{ bgcolor: 'background.paper', p: 1, alignContent: 'center' }} component="footer">
              <WorkShowcaseList images={images2} />
            </Box>
          </Grid>

          <Grid item xs={SMALL} sx={{ textAlign: "center" }}>
            <Typography variant="h4" align="center" sx={{ p: 2, pb: 6 }}>
              Working in and around the Hauraki District area, I can help you with any roof painting needs.
            </Typography>
            <iframe title="googleMaps" xs={{ width: "100%" }} width="100%" height="300" src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=Hauraki%20District&amp;t=&amp;z=8&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"><a href="https://www.gps.ie/truck-gps/">transport gps</a></iframe>
          </Grid>



          <Grid item xs={SMALL}>
            <Box sx={{ bgcolor: 'background.paper', alignContent: 'center', pt: 0 }} component="footer">
              <WorkShowcaseList images={images1} />
            </Box>
          </Grid>

          <Grid item xs={SMALL} sx={{ textAlign: "center" }}>
            <img id="astraPaints" src="astra-roof.png" alt="Astra Paints are proud suppliers to Hauraki Roof Painting" />
          </Grid>

          <Grid item xs={SMALL}>
            {/* rgba(21,31,64,0.95) */}
            <Box sx={{ bgcolor: 'white', borderRadius: 2, p: 1, }} component="footer">
              <ShowCase first_image='https://i.imgur.com/Fo5R3mZ.jpeg' second_image='https://i.imgur.com/3WWVCYY.jpeg' />
            </Box>
          </Grid>
        </Grid>
      </Container>

      <Footer />
    </ThemeProvider>
  );
}




export default App;
