
import AppBar from '@mui/material/AppBar';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
// import Footer from './components/Footer';

export default function Footer() {
    return (
        <Box sx={{ bgcolor: '#151F40', p: 4 }} component="footer">
            <Grid container spacing={6} justify="space-evenly">
                <Grid item xs={12} sm={4} md={4}>
                    <Typography variant="h6" align="center" color="white" gutterBottom style={{ fontWeight: 800 }}>
                        Contact
                    </Typography>
                    <Typography variant="subtitle1" align="center" color="white" component="p">
                        Email: <a href="mailto:haurakiroofpainting@gmail.com" className="whiteLink">Email Here</a>
                    </Typography>
                    <Typography variant="subtitle1" align="center" color="white" component="p">
                        Phone: <a href="tel:0272326788" className="whiteLink">027 232 6788</a>
                    </Typography>
                </Grid>

                <Grid item xs={12} sm={4} md={4} sx={{ textAlign: "center" }}>
                    {/* <Typography variant="h6" align="center" color="white" gutterBottom>
                        Experience
                    </Typography> */}
                    <Typography variant="h6" color="white">
                        25 Years experience repainting and restoring roofs
                    </Typography>
                </Grid>

                <Grid item xs={12} sm={4} md={4} sx={{ textAlign: "center" }}>
                    <Typography variant="h6" align="center" color="white" gutterBottom style={{ fontWeight: 800 }}>
                        Other Services
                    </Typography>
                    <Typography color="white">
                        Painting fences<br />Roof moss treatments
                    </Typography>
                </Grid>
            </Grid>
        </Box>
    );
}